export const locale = () => ({
  // the locale of formatting and parsing function
  formatLocale: {
    // MMMM
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    // MMM
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    // dddd
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    // ddd
    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    // dd
    weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    // first day of week
    firstDayOfWeek: 0,
    // first week contains January 1st.
    firstWeekContainsDate: 1,
  },
  // the calendar header, default formatLocale.weekdaysMin
  days: ["일", "월", "화", "수", "목", "금", "토"],
  // the calendar months, default formatLocale.monthsShort
  months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  // the calendar title of year
  yearFormat: 'YYYY년',
  // the calendar title of month
  monthFormat: 'MM월',
  // the calendar title of month before year
  monthBeforeYear: false,
})

export default locale
